import React from 'react'
import tw from 'twin.macro'
import Link, { OutLink, HomeLink } from './Link'
import { paypalMe, survey } from '../config.json'

const H1 = tw.h1`text-3xl mb-8`
const H2 = tw.h1`text-xl pt-3 mb-6 font-bold`
const P = tw.p`mb-4 leading-6`

export default function About() {
  return (
    <div tw="px-8 sm:px-16 md:px-0 md:w-1/2 max-w-74ch m-auto py-12">
      <H1>About cherrykit</H1>
      <P>
        First was the idea to create a design program for beautiful shapes that
        works on the web.
      </P>
      <H2>Parametric Design</H2>
      <P>
        We were interested in a "parametric design" approach, because you can
        design shapes once, then change measurements without having to re-draw
        anything.
      </P>
      <H2>Organic Geometry</H2>
      <P>
        We found design features to allow designers to make simple shapes more
        more beautiful with simple commands.
      </P>
      <H2>Signed Distance Functions</H2>
      <P>
        We settled on "signed distance functions" as it lets you design
        parametric shapes that would be hard or impossible to achieve with
        standard tools like openscad. As a bonus, it works on the web.
      </P>
      <H2>Examples</H2>
      <P>
        Some recent parts on this site explore these new design feature. Check
        out the <Link to="/thing/d9sr">Duplo Brio connector</Link> or the{' '}
        <Link to="/thing/cc1h">Uncovered Brio</Link> to see organic *transitions
        between shapes* that are beautiful, took no design effort and are still
        parametrizable. Aside from those, we've featured some standard parts
        that come in handy when doing home DIY.
      </P>
      <H2>Future</H2>
      <P>
        How should this project move forward? Please leave your thoughts in the{' '}
        <OutLink href={survey}>mini survey</OutLink>
      </P>
      <P>Thanks for visiting!</P>
      <HomeLink />
    </div>
  )
}
